import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trial"
export default class extends Controller {
  connect() {
    
    console.log("Sidenav Controller Loaded.");
    
    document.querySelector(".sidenav_button").addEventListener("click", adjustSidenav);
    
    function adjustSidenav() {
        //alert("Sidenav_button clicked")
        document.querySelector(".sidenav").classList.toggle(".slide_out");
    }
    
  }
}