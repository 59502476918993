import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="offcanvas-search"
export default class extends Controller {
  static targets = ["form", "input"]
  connect() {
    console.log(new Date().toISOString() + " | INFO | Offcanvas Search controller loaded.");
    this.timeout = null
  }

  update() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.performSearch()
    }, 300)
  }

  performSearch() {
    const query = this.inputTarget.value
    const url = new URL(this.formTarget.action)
    url.searchParams.set("query", query)

    fetch(url, {
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      }
    }).then(response => response.text()).then(html => {
      const frame = document.getElementById("organizations_list")
      if (frame) {
        frame.innerHTML = html
      } else {
        console.error("organizations_list frame not found")
      }
    }).catch(error => {
      console.error("Error fetching Turbo Stream:", error)
    })
  }
  
}
