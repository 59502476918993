// https://dev.to/dalezak/bootstrap-modals-using-hotwire-in-rails-3pkh
import { Modal } from "bootstrap"
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    
    this.element.addEventListener('turbo:submit-end', (event) => {
      console.log(event);
      if (event.detail.success) {
          console.log(new Date().toISOString() + " | INFO | Hiding Modal.")
          this.modal.hide();
      }
    });
    
    console.log(new Date().toISOString() + " | INFO | modal controller loaded");
    
    // document.addEventListener('turbo:submit-end', this.handleSubmit);
    
    let backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.remove();
    };
    
    this.modal = new Modal(this.element);
    this.modal.show();
    this.element.addEventListener('hidden.bs.modal', (event) => {
      this.element.remove();
    });
    
    
    /*----------------------------------------------------------------------------*/
    /* Enable Send button on contact form when google recaptcha is completed
    /*----------------------------------------------------------------------------*/
    //var enableLogin = function () {
    //  $('#login_button').prop("disabled",false);
    //};
    
    //Enable Login Button 
    function enableLogin() { 
      var loginButton = document.querySelector("#login_button");
      loginButton.disabled = false;
       console.log("enableLogin function called") 
    };     
    
    $('#session_email').on('blur input', function() {
        if ($('#session_password').val() != "") {
            console.log("password not blank")
            // Execute google recaptcha
            grecaptcha.execute();
        } else {
    
            $('#session_email').change(function(){
              
              //- Regular Expression Filter for checking an email address format
              var re = new RegExp();
                  re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
              
              var email = $('#session_email').val();
              
              // Check to see if input value is valid
              if(re.test(email)){
                $('#session_email').toggleClass('is-valid');
                $('#session_email').removeClass('is-invalid');
                // Execute google recaptcha
                grecaptcha.execute();
              } else {
                $('#session_email').toggleClass('is-invalid');
                $('#session_email').removeClass('is-valid');
                // Add an error message instructing users to enter a valid email address.
              } 
            });
                    
        }
    });
    
    function onSubmit(recaptchaToken) {
        // inject token as hidden form field and submit form
        console.log("Inserting Recaptcha Token")
    }
    
    // Clears registration form with any auto populated data that may have been inserted
    // when the page loaded.
    function toggleClearForm( e ){
       var user_email = document.getElementById('user_email');
       var user_password = document.getElementById('user_password');
       
       user_email.value = '';
       user_password.value = '';
    };
    
    
    // When the Terms of Service button is clicked from the account registration
    // modal, the following code is launched.
    function toggleAgreement( e ){
         var registration_button = document.getElementById('registration');
         var terms_button_icon = document.getElementById('terms_button_icon');
         
         if (terms_button_icon.classList.contains("fa-square")) {
             terms_button_icon.classList.replace("fa-square", "fa-check");
             terms_button_icon.classList.replace("far", "fas");
             terms_button_icon.classList.add("text-success");
             registration_button.classList.remove("disabled")
             registration_button.disabled = false
         } else {
             
         };
    };     
    
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.modal.hide()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.modal.hide()
    }
  }
  
}