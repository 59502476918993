import { Controller } from "@hotwired/stimulus"
//import { Confetti } from "stimulus-confetti"
import confetti from "canvas-confetti"

// Connects to data-controller="confetti"
export default class extends Controller {
  connect() {
    console.log(new Date().toISOString() + " | INFO | confetti js controller loaded");
    // import { Confetti } from "stimulus-confetti"    
    // application.register('confetti', Confetti)
    
    var end = Date.now() + (5 * 1000);
    
    var colors = ['#0052cc', '#00cc00', '#ffffff'];
    //var colors = [[0, 82, 204],[0, 204, 0],[255,255,255]]
    
    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });
    
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
    
  }
}
