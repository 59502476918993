import Dropzone from "dropzone"
import { Controller } from "@hotwired/stimulus"
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "../helpers";

// Connects to data-controller="jobs"
export default class extends Controller {
  connect() {
    
    console.log("Jobs Controller Loaded.");
    
    (function(){
	    // disable auto discover
	    Dropzone.autoDiscover = false;      
	    
      console.log("Function executed.");
      
      $("#import-job").dropzone({
          autoProcessQueue: false,
          parallelUploads: 1,
          maxFiles: 1,
          previewsContainer: ".dropzone-previews",
          dictDefaultMessage: '',
          clickable: '.dropzone',
          paramName:"job[file]",
          maxFilesize: 25600,
          addRemoveLinks: false,
          //acceptedFiles: [".csv", "text/csv", "application/vnd.ms-excel", "application/csv", "text/x-csv", "application/x-csv", "text/comma-separated-values", "text/x-comma-separated-values"],
          init: function() {
              console.log("DZ initi function executed.")
              var myDropzone=this;
              this.element.querySelector("button[type=submit]").addEventListener("click", function(e){
                  e.preventDefault();
                  e.stopPropagation();
                  myDropzone.processQueue();
      
              });
              this.on('addedfile', function(file){
                  $('.dz-message').hide();
              });
              this.on('success', function(file){
                  console.log(file.xhr.response);
              });
          }
      });
      
      //Dropzone.autoDiscover = false;
      
    	// grap our upload form by its id
    	//$("#import_job").dropzone({
      //  autoProcessQueue: false,
      //  parallelUploads: 1,
      //  maxFiles: 1,
      //  previewsContainer: ".dropzone-previews",
      //  dictDefaultMessage: '',
      //  //clickable: '.dz-message',
      //  paramName:"job[file]",
      //  maxFilesize: 25600,
      //  acceptedFiles: [".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"],    		
    	//	addRemoveLinks: false,
      //  init: function() {
      //      var myDropzone=this;
      //      this.element.querySelector("button[type=submit]").addEventListener("click", function(e){
      //          e.preventDefault();
      //          e.stopPropagation();
      //          myDropzone.processQueue();
      //  
      //      });
      //      this.on('addedfile', function(file){
      //          $('.upload-box').hide();
      //      });
      //      this.on('success', function(file){
      //          console.log(file.xhr.response);
      //      });
      //  }    		
    	//});	      
    
    }).call(this);
  }
}