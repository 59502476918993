// app/javascript/controllers/login_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "form"];

  connect() {
    console.log(new Date().toISOString() + " | INFO | Login controller connected");
    window.enableLogin = this.enableLogin.bind(this);
    console.log(new Date().toISOString() + " | INFO | Global enableLogin function registered", window.enableLogin);
    
    // Check for particlesJS before initializing it
    this.waitForParticlesJS(() => {
      this.initializeParticles();
    });    
    
    // Check for the button's existence before initializing reCAPTCHA
    this.waitForButton(() => {
      console.log(new Date().toISOString() + " | INFO | Button exists, initializing reCAPTCHA");
      this.waitForRecaptcha(() => {
        this.executeRecaptcha();
      });
    });
    
    // // Debugging for WebSocket connection
    // this.debugWebSocketConnection();    
  }

  waitForParticlesJS(callback) {
    if (typeof particlesJS !== "undefined") {
      callback();
    } else {
      console.log(new Date().toISOString() + " | INFO | Waiting for particlesJS to load...");
      setTimeout(() => this.waitForParticlesJS(callback), 100);
    }
  }
  
  waitForButton(callback) {
    if (this.buttonTarget) {
      callback();
    } else {
      console.log(new Date().toISOString() + " | INFO | Waiting for the button to load...");
      setTimeout(() => this.waitForButton(callback), 100);
    }
  }  
  
  waitForRecaptcha(callback) {
    if (typeof grecaptcha !== "undefined") {
      callback();
    } else {
      console.log(new Date().toISOString() + " | INFO | Waiting for reCAPTCHA to load...");
      setTimeout(() => this.waitForRecaptcha(callback), 100);
    }
  }

  executeRecaptcha() {
    try {
      console.log(new Date().toISOString() + " | INFO | Executing reCAPTCHA");
      grecaptcha.execute();
    } catch (error) {
      console.error(new Date().toISOString() + " | ERROR | Error executing reCAPTCHA:", error);
      setTimeout(() => {
        console.log(new Date().toISOString() + " | INFO | Retrying reCAPTCHA execution");
        this.executeRecaptcha();
      }, 1000);
    }
  }

  enableLogin() {
    this.buttonTarget.disabled = false;
    console.log(new Date().toISOString() + " | INFO | enableLogin function called");
  }
  
  initializeParticles() {
    particlesJS("particles-js", {
      "particles": {
        "number": {
          "value": 80,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#ffffff"
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          }
        },
        "opacity": {
          "value": 1,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.5,
            "sync": false
          }
        },
        "size": {
          "value": 0,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 2,
            "size_min": 0.1,
            "sync": true
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 250,
          "color": "#39afd1",
          "opacity": 0.1,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 3,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "bounce",
          "bounce": true,
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 200,
            "size": 20,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 200,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true
    });
  }
  
  debugWebSocketConnection() {
    document.addEventListener("turbo:before-fetch-request", () => {
      console.log("Before fetch request");
    });

    document.addEventListener("turbo:before-fetch-response", () => {
      console.log("Before fetch response");
    });

    document.addEventListener("turbo:fetch-request-error", (event) => {
      console.error("Fetch request error", event.detail);
    });

    document.addEventListener("turbo:fetch-response-error", (event) => {
      console.error("Fetch response error", event.detail);
    });
  }  
  
}