import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the Notifications channel.")
    let icon = document.querySelector("#notification_connection_icon")
    
    if (icon) {
      icon.classList.toggle('text-danger');
      icon.classList.toggle('text-success');
    };
    
    const statusIcon = document.getElementById("notificationConnectionStatusIcon");
    const statusDot = document.getElementById("notificationConnectionStatusDot");
    const statusText = document.getElementById("notificationConnectionStatusText");
    
    if (statusIcon) {
      statusIcon.classList.toggle("text-success", true);
      statusIcon.classList.toggle("text-danger", false);      
    }    
    
    if (statusDot) {
      statusDot.classList.toggle("bg-success", true);
      statusDot.classList.toggle("bg-danger", false);
    }

    if (statusText) {
      statusText.textContent = "Connected";
      statusText.classList.toggle("text-success", true);
      statusText.classList.toggle("text-danger", false);
    }
    
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("Disconnected from the Notifications channel.")
    
    let icon = document.querySelector("#notification_connection_icon")
    
    if (icon) {
      icon.classList.toggle('text-danger');
      icon.classList.toggle('text-success');
    };
    
    const statusIcon = document.getElementById("notificationConnectionStatusIcon");
    const statusDot = document.getElementById("notificationConnectionStatusDot");
    const statusText = document.getElementById("notificationConnectionStatusText");
    
    if (statusIcon) {
      statusIcon.classList.toggle("text-danger", true);
      statusIcon.classList.toggle("text-success", false);      
    }    
    
    if (statusDot) {
      statusDot.classList.toggle("bg-danger", true);
      statusDot.classList.toggle("bg-success", false);
    }

    if (statusText) {
      statusText.textContent = "Disconnected";
      statusText.classList.toggle("text-danger", true);
      statusText.classList.toggle("text-success", false);
    }    
    
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // Test from Rails Console:
    // ActionCable.server.broadcast('notifications_channel',{ message:"Cool"})
    // ActionCable.server.broadcast('notifications_channel',{ type: "primary", title: "Import Job Queued", icon: "fa fa-circle-notch fa-spin text-secondary me-1", delay: 600000, url: "", message: "Some message for the user will go here."})
    // ActionCable.server.broadcast('notifications_channel',{ auid: "all", type: "primary", title: "Job Queued", delay: 600000, url: "/drugs", message: "Some message for the user will go here."})
    // Notification.create(user_id: 1, notification_type:"primary", title: "Job Queued", message:"A job to import data has been queued up for processing. You will be notified if import fails or succeeds.")

    // console.log("Notifications channel is working");
    // console.log(data["message"]);
    
    //- https://grotesquegentleadvance--samkhaled.repl.co/
    
    (function() {
      let user_id = document.querySelector('body').dataset.auid;
      let message_count = document.querySelector('#message_count');
      
      if (data.auid == user_id && data.increase_counter) {
        message_count.dataset.count = ++message_count.dataset.count
      };
      
      //- Note: (4/25/2022) target was removed from the url link in the notification.
      //- I needed to leverage turbo frames to open the offcanvas menu and not redirect
      //- when the user clicked the notifiication.
      if (data.auid == user_id || data.auid == "all") {  
        $.notify({
          title: data.title,
          message: data.message,
          url: data.url,
          target: '_parent'
        }, {
          element: 'body',
          position: null,
          type: data.type,
          allow_dismiss: true,
          newest_on_top: false,
          showProgressbar: true,
          placement: {
            from: 'bottom',
            align: 'right'
          },
          offset: {
            x: 20,
            y: 20
          },
          spacing: 10,
          width: 390,
          z_index: 100000,
          delay: data.delay,
          timer: 1000,
          url_target: '_blank',
          mouse_over: null,
          animate: {
            enter: 'animated bounceInRight',
            exit: 'animated bounceOutRight'
          },
          onShow: null,
          onShown: null,
          onClose: null,
          onClosed: null,
          icon_type: 'class',
          template: '<div data-notify="container" class="col-xs-12 col-sm-3 bg-white text-secondary alert alert-{0}-alt p-0 border rounded shadow-lg" role="alert">' + 
          '<button type="button" aria-hidden="true" class="btn-close text-reset mt-1" data-notify="dismiss"></button>' + 
          '<div class="notification-title border-bottom">' + 
          '<span data-notify="title">{1}</span> </div>' + 
          '<p class="p-3 mb-0"> <span class="" data-notify="message">{2}</span> </p>' + 
          '<div class="progress mx-3 mt-1 mb-3" data-notify="progressbar">' + 
          '<div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' + '</div>' + '<a href="{3}" data-notify="url" data-turbo="true" data-turbo-frame="offcanvas_menu"></a>' + 
          '</div>'
        });
      };
    }).call(this);
    
  }
});
