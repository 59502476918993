import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="close-offcanvas"
export default class extends Controller {
  connect() {
    console.log(new Date().toISOString() + " | INFO | close_offcanvas controller loaded.");
    this.element.addEventListener("turbo:before-fetch-request", this.closeOffcanvas.bind(this));
  }

  closeOffcanvas(event) {
    console.log("Closing offcanvas...");
    let offcanvasElement = document.getElementById('offcanvas');
    let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
    if (offcanvasInstance) {
      offcanvasInstance.hide();
    }
  }
}
