import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-notification"
export default class extends Controller {
  connect() {
    console.log("Notification controller connected");
    this.showNotification();
  }

  showNotification() {
    const notification = document.getElementById('notification');
    if (notification) {
      const title = notification.dataset.title;
      const message = notification.dataset.message;
      const type = notification.dataset.type;

      $.notify({
        title: title,
        message: message,
        url: '',
        target: '_parent'
      }, {
        element: 'body',
        position: null,
        type: type,
        allow_dismiss: true,
        newest_on_top: false,
        showProgressbar: true,
        placement: {
          from: 'bottom',
          align: 'right'
        },
        offset: {
          x: 20,
          y: 20
        },
        spacing: 10,
        width: 390,
        z_index: 100000,
        delay: 5000,
        timer: 1000,
        mouse_over: null,
        animate: {
          enter: 'animated bounceInRight',
          exit: 'animated bounceOutRight'
        },
        onShow: null,
        onShown: null,
        onClose: null,
        onClosed: null,
        icon_type: 'class',
        template: `
          <div data-notify="container" class="col-xs-12 col-sm-3 alert alert-{0}-alt p-0 border rounded" role="alert">
            <button type="button" aria-hidden="true" class="btn-close text-reset mt-1" data-notify="dismiss"></button>
            <div class="notification-title border-bottom text-primary">
              <span data-notify="title">{1}</span>
            </div>
            <p class="p-3 mb-0">
              <span class="" data-notify="message">{2}</span>
            </p>
            <div class="progress mx-3 mt-1 mb-3" data-notify="progressbar">
              <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>
            </div>
            <a href="{3}" data-notify="url" data-turbo="true" data-turbo-frame="offcanvas_menu"></a>
          </div>
        `
      });

      // Remove the notification element after displaying
      // notification.remove();
    }
  }
}