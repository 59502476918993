import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "source"]

  connect() {
    console.log(new Date().toISOString() + " | INFO | Filter controller connected");
    this.debounceTimeout = null
  }

  update() {
    clearTimeout(this.debounceTimeout)
    this.debounceTimeout = setTimeout(() => {
      if (this.hasFormTarget) {
        console.log("Submitting form:", this.formTarget)
        this.submitFormAsTurboStream()
      } else {
        console.error("Form target not found")
      }
    }, 300) // Adjust the debounce delay as needed
  }

  reset() {
    if (this.hasFormTarget) {
      console.log("Resetting form:", this.formTarget)
      this.formTarget.reset()
      this.formTarget.querySelectorAll('input[type="hidden"]').forEach(input => {
        input.value = ""
      })
      this.update()
    } else {
      console.error("Form target not found")
    }
  }

  toggleTrials(event) {
    const isChecked = event.target.checked
    this.sourceTarget.value = isChecked ? 'organization' : 'trials'
    this.update()
  }

  submitFormAsTurboStream() {
    const url = new URL(this.formTarget.action)
    url.search = new URLSearchParams(new FormData(this.formTarget)).toString()
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    }).then(response => response.text()).then(html => {
      console.log("Turbo Stream response received")
      Turbo.renderStreamMessage(html)
    }).catch(error => console.error('Error:', error))
  }
}
