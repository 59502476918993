// https://dev.to/dalezak/bootstrap-modals-using-hotwire-in-rails-3pkh
import * as bootstrap from "bootstrap"
import "@selectize/selectize"
import {
  Controller
} from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    
    //------------------------------------------------------------------------//
    //- Selectize Example in RoR 5.2
    //------------------------------------------------------------------------//
    //- https://github.com/gorails-screencasts/gorails-episode-178/blob/master/app/assets/javascripts/posts.js#L16
    //------------------------------------------------------------------------//
    // 1. Create selectizeCallback and set to null
    // 2. Create event listener on modal hide. Resets selectizeCallback to null 
    //    and resets the form
    // 3. Prevent default submit on the form inside the modal. This form is 
    //    already loaded in the dom (important to understand)
    // 4. Selectize Create function
    //    a. selectizeCallback = callback
    //    b. Toggle modal and make the form visible
    //    c. Take the input value and set it to the value of the name field in 
    //       the create form.
    //------------------------------------------------------------------------//
    
    console.log(new Date().toISOString() + " | INFO | Selectize JS Controller Loaded.");
    
    //------------------------------------------------------------------------//
    //- Selectize
    //------------------------------------------------------------------------//
    // 1. Create selectizeCallback and set to null
    // 2. Selectize Create function
    //    a. selectizeCallback = callback
    //    b. invoke a function which uses AJAX to get the current inputs create
    //       form. 
    //        1. After a successful AJAX call wait for the modal to display which
    //          contains the new *Model* form for the corresponding input.
    //        2. On AJAX complete invoke a function to wait until modal is 
    //             visible, then set the value of the current forms name to the 
    //             value of input.
    //        3. Set form name value using input.
    //------------------------------------------------------------------------//
    
    var selectizeCallback = null
    
    // https://stackoverflow.com/questions/57429677/javascript-make-a-word-singular-singularize
    function singularize(word) {
        const endings = {
            ves: 'fe',
            ies: 'y',
            i: 'us',
            zes: 'ze',
            //ses: 's',
            uses: 'us',
            es: 'e',
            s: ''
        };
        return word.replace(
            new RegExp(`(${Object.keys(endings).join('|')})$`), 
            r => endings[r]
        );
    };
    
    function renderCreateForm(endpoint_url, input, input_label, selectizeCallback){
      var deferred = $.Deferred();

      //- Add value to input form name
      var name_input_id = "#" + singularize(input_label) + "_name";
      console.log(name_input_id);
      
      $.ajax({
        url: endpoint_url,
        cache: true,
        type: 'GET',
        data: {},
        //headers: {"Turbo-Frame": "modal"},
        dataType: 'html',
        success: function(response, textStatus, xhr) {
          console.log(new Date().toISOString() + " | INFO | AJAX call was successful, loading modal.");
          
          var endpoint_ts_url = endpoint_url + "?_=" + Date.now()
          
          //- Required for Turbo-Frame to load, in this case the #Modal
          //$("#modal").attr({"reloadable":"", "src": endpoint_url });
          $("#modal").attr({"src": endpoint_url });
          
          console.log( endpoint_ts_url);
        },
        complete: function () {
          
          // Wait for BS Modal to render
          setTimeout( function() {
            if ($('#Modal').is(':visible')) {
              
              console.log("Modal Show Event Triggered");
              
              $("#Modal").on("hide.bs.modal", function() {
                if (selectizeCallback != null) {
                  selectizeCallback();
                  selectizeCallback = null;
                };
                
                console.log("Modal Closed, resetting selectizeCallback");
                
              });
              
              $(name_input_id).val(input);
              
            };
            
            setup_post_form_submit_el(input, input_label, selectizeCallback);
            
          }, 750);
        }
      }); //- End: AJAX call
      
      return deferred.promise();
    };
    
    function setup_post_form_submit_el(input, input_label, selectizeCallback){
      var form_id = "#new_" + singularize(input_label);
      
      console.log(form_id);
      
      $(form_id).on("submit", function(e) {
        e.preventDefault();
        $.ajax({
          method: "POST",
          url: $(this).attr("action"),
          data: $(this).serialize(),
          dataType: "json",
          success: function(response) {
            selectizeCallback({value: response.id, text: response.name});
            selectizeCallback = null;
            
            // Toggle Modal
            $(".modal").modal('toggle');
            
          }
        });
      });      
    };    

    //------------------------------------------------------------------------//
    //- Selectize
    //------------------------------------------------------------------------//
    $(".selectize").selectize({
      openOnFocus: false,
      onInitialize: function () {
          var that = this;
  
          this.$control.on("click", function () {
              that.ignoreFocusOpen = true;
              setTimeout(function () {
                  that.ignoreFocusOpen = false;
              }, 1);
          });
      },
      onFocus: function () {
          if (!this.ignoreFocusOpen) {
              this.open();
          }
      },
      plugins: ["auto_select_on_type"],
      preload: false,
      create: function(input, callback) {
        
        selectizeCallback = callback;
        
        //--------------------------------------------------------------------//
        // 1. Fetch the form for the current selectize field.
        // 2. Place input value into corresponding create form field
        // 3. Prevent the default submit
        // 4. The return data from the submit new form
        //--------------------------------------------------------------------//

        var input_label = (this.$input).siblings("label").text().toLowerCase();
        var endpoint_url = "/" + input_label + "/new";
        
        renderCreateForm(endpoint_url, input, input_label, selectizeCallback);
        
      }
    });    
    
  }
}