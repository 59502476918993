import {
  Controller
} from "@hotwired/stimulus"
export default class extends Controller {
    connect() {

        //------------------------------------------------------------------------//
        //- Search Form
        //------------------------------------------------------------------------//
        let selectStudyId = document.getElementById('trials_study_id');
        let selectBiomarkerId = document.getElementById('trials_biomarker_ids');
        let selectPrimaryId = document.getElementById('trials_primary_ids');
        let selectClinicalIntentId = document.getElementById('trials_clinical_intent_id');
        let selectClinicalStateId = document.getElementById('trials_clinical_state_id');
        
        selectStudyId.addEventListener("input", (e) => {
          if ( selectStudyId.value !== '' ) {
            selectBiomarkerId.disabled = true;
            selectPrimaryId.disabled = true;
            selectClinicalIntentId.disabled = true;
            selectClinicalStateId.disabled = true;
          } else {
            selectBiomarkerId.disabled = false;
            selectPrimaryId.disabled = false;
            selectClinicalIntentId.disabled = false;
            selectClinicalStateId.disabled = false;
          }
        });
        
        //- Add EventListeners on all but the Study ID input that will detect a
        //- change, if changed to empty then enable study id input.
        selectBiomarkerId.addEventListener("change", (e) => {
            if ( selectBiomarkerId.value !== '' ) {
                selectStudyId.disabled = true;
                selectPrimaryId.disabled = true;
            } else {
                selectStudyId.disabled = false;
                selectPrimaryId.disabled = false;
            };

            //- Get all selected Options
            //https://css-tricks.com/dynamic-dropdowns/
            //var selected = document.querySelector('#trials_biomarker_ids option:checked').toArray().map(item => item.value).join();
            let selected = $('#trials_biomarker_ids option:selected').toArray().map(item => item.value).join();
            let selectedArray = (selected);
            console.log(selected);

            let params = {
                biomarkers: selected
            };

            console.log(params);
            
            //for (let i = 0; i < selected.length; i++) {
            //    params["primaries"].push([selected[i]]);
            //    console.log("Array Item : " + selected[i]);
            //}
            
            //- Fetch valid Primaries from Biomarker selection and update the
            //- Primary multi-select options.
            
            //let options = {
            //  method: 'GET',      
            //  headers: {}
            //};
            
            //fetch('/get-primaries', options)
            //.then(response => response.json())
            //.then(body => {
              // Do something with body
            //  selectPrimaryId.options.length = 0;
            //});
            
            let getData = async (url) => {
                let options = {
                  method: 'GET',      
                  headers: {}
                };
                let res = await fetch(url, options);
                let json = await res.json();
                console.log(json);
                return json
            };
            
            let url = "/get-primaries?" + new URLSearchParams(params);
            
            // Clear out Primary multiselect options.
            selectPrimaryId.options.length = 0;
            selectPrimaryId.classList.add("text-center");
            //selectPrimaryId.innerHTML += '<option class="spinner-grow text-secondary my-5"></option>';
                
            getData(url)
                .then((json) => {
                    
                    // Reset select menu
                    selectPrimaryId.options.length = 0;
                    selectPrimaryId.classList.remove("text-center");
                        
                    // Rebuild list from json response.
                    Object.values(json).forEach(value => {
                        console.log(value[0]);
                        selectPrimaryId.innerHTML += "<option value=" + value[0] + ">" + value[1] + "</option>";
                    });
                    
                });
            
            selectPrimaryId.disabled = false;
            
        });
        
        selectPrimaryId.addEventListener("change", (e) => {
            if ( selectPrimaryId.value !== '' ) {
                selectStudyId.disabled = true;
                //selectBiomarkerId.disabled = true;
            } else {
                selectStudyId.disabled = false;
                //selectBiomarkerId.disabled = false;
            }
        });
        
        selectClinicalIntentId.addEventListener("change", (e) => {
            if ( selectClinicalIntentId.value !== '' ) {
                selectStudyId.disabled = true;
            } else {
                selectStudyId.disabled = false;
            }
        });
        
        selectClinicalStateId.addEventListener("change", (e) => {
            if ( selectClinicalStateId.value !== '' ) {
                selectStudyId.disabled = true;
            } else {
                selectStudyId.disabled = false;
            }
        });
        
        
    }
}