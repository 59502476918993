// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails"
Turbo.session.drive = true
import './add_jquery'
// import "./controllers"
import * as bootstrap from "bootstrap"
//import "bootstrap-multiselect"
//import "bootstrap-select"
import "bootstrap4-notify"
import "./channels"
import "dropzone"
import "trix"
import "@rails/actiontext"
//import "chartkick"
import "chartkick/chart.js"
import "@selectize/selectize"
import "./controllers"

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))  
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {  
  return new bootstrap.Popover(popoverTriggerEl)
})

//-----------------------------------------------------------
//- PWA | Service Worker
//-----------------------------------------------------------
//- https://onrails.blog/2019/01/08/easy-pwas-the-rails-way/
//-----------------------------------------------------------
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
    .then(function(reg) {
      console.log('[Companion]', 'Service worker registered!');
      console.log(reg);
    })
    .catch(function(error) {
      console.log('Service worker registration failed:', error);
    });    
}


//- https://stackoverflow.com/questions/71985655/service-worker-cacheing-breaking-google-maps-api
//cacheId = cacheIdRelace;
//staticCache = "static-" + cacheId;
//dynamicCache = "dynamic-" + cacheId;

self.addEventListener('fetch', function (event) {
    // no caching google api
    if (!(event.request.url.indexOf(self.location.host) > -1)) {
        event.respondWith(
            fetch(event.request).then(
                function (response) {
                    // Check if we received a valid response
                    if (!response) {
                        console.error("fetch eventhandler error: no response");
                        return Response.error();
                    }
                    if (!response.ok) {
                        console.warn("fetch eventhandler warning: status=", response.status, response.type);
                        return response;
                    }
                    // ok
                    return response;
                }).catch(function () {
                  // failed access
                  console.error("fetch eventhandler error");
                  return Response.error();
            }))
    } else {
        event.respondWith(
            caches.match(event.request).then(cacheRes => {
                return cacheRes || fetch(event.request).then(fetchRes => {
                    return caches.open(dynamicCache).then(cache => {
                        cache.put(event.request.url, fetchRes.clone());
                        return fetchRes
                    })
                });
            }).catch(() => caches.match('/offline/'))
        );
    }
});