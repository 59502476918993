import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updatePerPage(event) {
    const perPageValue = event.target.value
    const filterForm = document.querySelector('[data-controller="filter"] form')
    const perPageField = filterForm.querySelector('input[name="trials[per_page]"]')

    if (perPageField) {
      perPageField.value = perPageValue
      filterForm.requestSubmit()
    } else {
      console.error("per_page hidden field not found in the filter form")
    }
  }
}
