import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account-activation"
export default class extends Controller {
  connect() {
    
    //- Log controller loaded message to console.
    console.log(new Date().toISOString() + " | INFO | account_activation js controller loaded");
    
    //-
    document.addEventListener('DOMContentLoaded', function () {
      var passwordInput = document.getElementById('user_password');
      var confirmPasswordInput = document.getElementById('user_password_confirmation');
      var passwordError = document.getElementById('password_error');
      var submitButton = document.getElementById('password_submit_button');

      function validatePassword() {
        if (passwordInput.value !== confirmPasswordInput.value) {
          passwordError.classList.remove("text-success");
          passwordError.classList.add("text-danger");
          passwordError.textContent = 'Passwords do not match!';
          
          submitButton.disabled = true;
        } else {
          passwordError.classList.remove("text-danger");
          passwordError.classList.add("text-success");
          passwordError.textContent = 'Passwords match!';
          submitButton.disabled = false;
        }
      }

      passwordInput.addEventListener('input', validatePassword);
      confirmPasswordInput.addEventListener('input', validatePassword);
    });
  }
}
