// https://dev.to/dalezak/bootstrap-modals-using-hotwire-in-rails-3pkh
import * as bootstrap from "bootstrap"
import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log(new Date().toISOString() + " | INFO | Header controller connected"); 

    //-----------------------------------------------------------
    //- Bootstrap 5 Dropdown Menu | Hover and Multi-level
    //-----------------------------------------------------------
    //- https://stackoverflow.com/questions/65913249/what-is-the-clean-way-to-implement-bootstrap-5-multi-level-dropdown-vertical-nav
    //-----------------------------------------------------------
    (function($bs) {
        const CLASS_NAME = 'has-child-dropdown-show';
        $bs.Dropdown.prototype.toggle = function(_orginal) {
            return function() {
                document.querySelectorAll('.' + CLASS_NAME).forEach(function(e) {
                    e.classList.remove(CLASS_NAME);
                });
                let dd = this._element.closest('.dropdown').parentNode.closest('.dropdown');
                for (; dd && dd !== document; dd = dd.parentNode.closest('.dropdown')) {
                    dd.classList.add(CLASS_NAME);
                }
                return _orginal.call(this);
            }
        }($bs.Dropdown.prototype.toggle);
    
        document.querySelectorAll('.dropdown').forEach(function(dd) {
            dd.addEventListener('hide.bs.dropdown', function(e) {
                if (this.classList.contains(CLASS_NAME)) {
                    this.classList.remove(CLASS_NAME);
                    e.preventDefault();
                }
                e.stopPropagation(); // do not need pop in multi level mode
            });
        });
    
        // for hover
        document.querySelectorAll('.dropdown-hover, .dropdown-hover-all .dropdown').forEach(function(dd) {
            dd.addEventListener('mouseenter', function(e) {
                let toggle = e.target.querySelector(':scope>[data-bs-toggle="dropdown"]');
                if (!toggle.classList.contains('show')) {
                    $bs.Dropdown.getOrCreateInstance(toggle).toggle();
                    dd.classList.add(CLASS_NAME);
                    $bs.Dropdown.clearMenus();
                }
            });
            dd.addEventListener('mouseleave', function(e) {
                let toggle = e.target.querySelector(':scope>[data-bs-toggle="dropdown"]');
                if (toggle.classList.contains('show')) {
                    $bs.Dropdown.getOrCreateInstance(toggle).toggle();
                }
            });
        });
    })(bootstrap);    
    
    
    //--------------------------------------------------------------
    // Theme Toggle
    //--------------------------------------------------------------
    $("#appThemeDefault").on("click", function(){
      if ($('html').attr("data-bs-theme") == "light") {
        $('html').attr("data-bs-theme", "dark")
      } else {
        $('html').attr("data-bs-theme", "light")
      };
    });
    
    
  }
  
  navigate(event) {
    event.preventDefault()
    const url = event.currentTarget.dataset.url
    Turbo.visit(url, { frame: "main" })
    history.pushState({}, "", url)
  } 
  
}