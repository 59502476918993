// app/javascript/controllers/search_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "query", "results", "clinicalIntent", "clinicalState",
    "biomarkers", "primaries", "filterButton", "inputGroup", "organizationTrials"
  ]

  connect() {
    console.log(new Date().toISOString() + " | INFO | Searchbar controller connected");
    this.hideDropdown = this.hideDropdown.bind(this);
    this.setupFilterDropdown();
    this.setupFilterListeners();
  }

  setupFilterDropdown() {
    const filterDropdown = document.getElementById('filterDropdown');
    if (filterDropdown) {
      filterDropdown.addEventListener('hide.bs.dropdown', () => {
        this.closeDropdown();
      });
      filterDropdown.addEventListener('show.bs.dropdown', () => {
        this.filterButtonTarget.classList.remove('no-bottom-right-radius');
        this.showResultsIfExist();
      });

      // Prevent the filter dropdown from closing when clicking inside the search input
      this.queryTarget.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    }
  }

  setupFilterListeners() {
    if (this.clinicalIntentTarget) {
      this.clinicalIntentTarget.addEventListener('change', () => this.performSearch());
    }
    if (this.clinicalStateTarget) {
      this.clinicalStateTarget.addEventListener('change', () => this.performSearch());
    }
    if (this.biomarkersTarget) {
      this.biomarkersTarget.addEventListener('change', () => this.performSearch());
    }
    if (this.primariesTarget) {
      this.primariesTarget.addEventListener('change', () => this.performSearch());
    }
    if (this.organizationTrialsTarget) {
      this.organizationTrialsTarget.addEventListener('change', () => this.performSearch());
    }
  }

  query() {
    this.performSearch();
  }

  showDropdown(event) {
    console.log("Search input focused");
    event.stopPropagation(); // Prevent the filter dropdown from closing
    this.element.classList.add('search-input-focused');
    this.setResultsWidth();
    if (this.hasResults()) {
      this.resultsTarget.classList.add('show');
      this.queryTarget.classList.remove('rounded-corners');
      this.queryTarget.classList.add('no-rounded-corners');
      this.filterButtonTarget.classList.add('no-bottom-right-radius');
    }
    document.addEventListener('click', this.hideDropdown);
  }

  showResultsIfExist() {
    if (this.hasResults()) {
      this.resultsTarget.classList.add('show');
      this.queryTarget.classList.remove('rounded-corners');
      this.queryTarget.classList.add('no-rounded-corners');
      this.filterButtonTarget.classList.add('no-bottom-right-radius');
    }
  }

  hideDropdown(event) {
    if (!this.element.contains(event.target) && !document.getElementById('filterDropdown').contains(event.target)) {
      console.log("Click outside search input and filter dropdown");
      this.closeDropdown();
    }
  }

  closeDropdown() {
    console.log("Closing dropdown");
    this.resultsTarget.classList.remove('show');
    this.queryTarget.classList.add('rounded-corners');
    this.queryTarget.classList.remove('no-rounded-corners');
    this.filterButtonTarget.classList.remove('no-bottom-right-radius');
    document.removeEventListener('click', this.hideDropdown);
  }

  resetFilters(event) {
    event.stopPropagation();
    this.clinicalIntentTarget.value = "";
    this.clinicalStateTarget.value = "";
    this.biomarkersTarget.selectedIndex = -1;
    this.primariesTarget.selectedIndex = -1;
    this.organizationTrialsTarget.checked = false;
    this.performSearch();
  }

  preventClose(event) {
    event.stopPropagation();
  }

  performSearch() {
    const query = this.queryTarget.value;
    const clinicalIntent = this.clinicalIntentTarget.value;
    const clinicalState = this.clinicalStateTarget.value;
    const biomarkers = Array.from(this.biomarkersTarget.selectedOptions).map(option => option.value);
    const primaries = Array.from(this.primariesTarget.selectedOptions).map(option => option.value);
    const organizationTrialsOnly = this.organizationTrialsTarget.checked;

    console.log(`Query: ${query}, Clinical Intent: ${clinicalIntent}, Clinical State: ${clinicalState}, Biomarkers: ${biomarkers}, Primaries: ${primaries}, Organization Trials Only: ${organizationTrialsOnly}`);

    fetch(`/search?query=${query}&clinical_intent=${clinicalIntent}&clinical_state=${clinicalState}&biomarkers=${biomarkers.join(",")}&primaries=${primaries.join(",")}&organization_trials_only=${organizationTrialsOnly}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => {
      console.log("Search results fetched");
      this.resultsTarget.innerHTML = html;
      this.checkResults();
    })
    .catch(error => console.error('Error fetching search results:', error));
  }

  checkResults() {
    if (this.hasResults()) {
      this.resultsTarget.classList.add('show');
      this.queryTarget.classList.remove('rounded-corners');
      this.queryTarget.classList.add('no-rounded-corners');
      this.filterButtonTarget.classList.add('no-bottom-right-radius');
    } else {
      this.closeDropdown();
    }
    this.setResultsWidth();
  }

  hasResults() {
    return this.resultsTarget.children.length > 0;
  }

  setResultsWidth() {
    const inputGroup = this.inputGroupTarget;
    const searchResults = this.resultsTarget;
    searchResults.style.width = `${inputGroup.offsetWidth}px`;
  }

  highlightQuery(query) {
    const regex = new RegExp(`(${query})`, 'gi');
    this.resultsTarget.querySelectorAll('.list-group-item').forEach(item => {
      this.highlightText(item, regex);
    });
  }

  highlightText(element, regex) {
    const highlightNode = (node) => {
      const match = node.nodeValue.match(regex);
      if (match) {
        const highlightElement = document.createElement('mark');
        const matchIndex = node.nodeValue.indexOf(match[0]);
        const afterMatchText = node.splitText(matchIndex);
        afterMatchText.nodeValue = afterMatchText.nodeValue.substring(match[0].length);
        highlightElement.appendChild(document.createTextNode(match[0]));
        node.parentNode.insertBefore(highlightElement, afterMatchText);
      }
    };

    const traverseNodes = (node) => {
      if (node.nodeType === 3) { // Text node
        highlightNode(node);
      } else if (node.nodeType === 1 && node.childNodes && !/(script|style)/i.test(node.tagName)) { // Element node
        Array.from(node.childNodes).forEach(childNode => traverseNodes(childNode));
      }
    };

    traverseNodes(element);
  }
}