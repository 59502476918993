import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  const organizationMetaTag = document.querySelector('meta[name="organization-id"]');
  const organizationId = organizationMetaTag ? organizationMetaTag.content : null;

  if (organizationId) {
    consumer.subscriptions.create({ channel: "OrganizationNotificationsChannel", organization_id: organizationId }, {
      connected() {
        // Called when the subscription is ready for use on the server
    
        console.log(`Connected to the OrganizationChannel for organization_id: ${organizationId}`);
    
        const statusIcon = document.getElementById("organizationConnectionStatusIcon");
        const statusDot = document.getElementById("organziationConnectionStatusDot");
        const statusText = document.getElementById("organizationConnectionStatusText");
        
        if (statusIcon) {
          statusIcon.classList.toggle("text-success", true);
          statusIcon.classList.toggle("text-danger", false);
        }    
        
        if (statusDot) {
          statusDot.classList.toggle("bg-success", true);
          statusDot.classList.toggle("bg-danger", false);
        }
    
        if (statusText) {
          statusText.textContent = "Connected";
          statusText.classList.toggle("text-success", true);
          statusText.classList.toggle("text-danger", false);
        }
        
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
    
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        
        let org_id = document.querySelector('body').dataset.ouid;
        if (data.ouid == org_id) {
          $.notify({
            title: data.title,
            message: data.message,
            url: data.url,
            target: '_parent'
          }, {
            element: 'body',
            position: null,
            type: data.type,
            allow_dismiss: true,
            newest_on_top: false,
            showProgressbar: true,
            placement: {
              from: 'bottom',
              align: 'right'
            },
            offset: {
              x: 20,
              y: 20
            },
            spacing: 10,
            width: 390,
            z_index: 100000,
            delay: data.delay,
            timer: 1000,
            url_target: '_blank',
            mouse_over: null,
            animate: {
              enter: 'animated bounceInRight',
              exit: 'animated bounceOutRight'
            },
            onShow: null,
            onShown: null,
            onClose: null,
            onClosed: null,
            icon_type: 'class',
            template: '<div data-notify="container" class="col-xs-12 col-sm-3 alert alert-dark-alt p-0 border rounded" role="alert">' + 
            '<button type="button" aria-hidden="true" class="btn-close text-reset mt-1" data-notify="dismiss"></button>' + 
            '<div class="notification-title border-bottom">' + 
            '<span data-notify="title" class="">{1}</span> </div>' + 
            '<p class="p-3 mb-0"> <span class="" data-notify="message">{2}</span> </p>' + 
            '<div class="progress mx-3 mt-1 mb-3" data-notify="progressbar">' + 
            '<div class="progress-bar bg-primary" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' + '</div>' + '<a href="{3}" data-notify="url" data-turbo="true" data-turbo-frame="offcanvas_menu"></a>' + 
            '</div>'
          });
        }        
        
        if (data.user_count) {
          document.getElementById("organizationUserCount").textContent = `${data.user_count}`;
        }        
      }
    });
  } else {
    console.log("No organization_id found in meta tag.");
  }
});