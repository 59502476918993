import { Modal } from "bootstrap"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trials-toolbar"
export default class extends Controller {
  connect() {
    
    console.log(new Date().toISOString() + " | INFO | trials-toolbar controller loaded.");
    
    $('#trialsGridViewButton').on("click", function(){
      
      console.log("Grid view button was clicked.")
      
    });
    
  }
}
