import { Modal } from "bootstrap"
import "@selectize/selectize"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trial"
export default class extends Controller {
  connect() {
    
    console.log(new Date().toISOString() + " | INFO | trials controller loaded.");
        
    //----------------------------------------------------------------------------//
    // https://stevepolito.design/blog/create-a-nested-form-in-rails-from-scratch/
    //----------------------------------------------------------------------------//
    // app/javascript/packs/nested-forms/addFields.js
    class addFields {
    // This executes when the function is instantiated.
      constructor() {
        console.log(new Date().toISOString() + " | INFO | addFields() instantiated!")
        this.links = document.querySelectorAll('.add_fields')
        this.iterateLinks()
      }
      
      iterateLinks() {
        // If there are no links on the page, stop the function from executing.
        if (this.links.length === 0) return
        // Loop over each link on the page. A page could have multiple nested forms.
        this.links.forEach(link => {
          link.addEventListener('click', e => {
            this.handleClick(link, e)
          })
        })
      }
      
      handleClick(link, e) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Save a unique timestamp to ensure the key of the associated array is unique.
        let time = new Date().getTime()
        // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
        let linkId = link.dataset.id
        // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
        let regexp = linkId ? new RegExp(linkId, 'g') : null
        // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
        let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
        // Add the new markup to the form if there are fields to add.
        //newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null
        newFields ? link.closest(".btn-toolbar").insertAdjacentHTML('beforebegin', newFields) : null
      }
    };
      
    // Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
    //window.addEventListener('load', new addFields())
    
    // app/javascript/packs/nested-forms/removeFields.js
    class removeFields {
      // This executes when the function is instantiated.
      constructor() {
        console.log(new Date().toISOString() + " | INFO | removeFields() instantiated!")
        this.iterateLinks()
      }
    
      iterateLinks() {
        // Use event delegation to ensure any fields added after the page loads are captured.
        console.log("iterate over links");
        document.addEventListener('click', e => {
          //if (e.target && e.target.className == 'remove_fields') {
          if (e.target && e.target.classList.contains('remove_fields') ) {
            this.handleClick(e.target, e)
          }
        })
      }
    
      handleClick(link, e) {
        console.log("Handle Click");
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Find the parent wrapper for the set of nested fields.
        let fieldParent = link.closest('.nested-fields')
        // If there is a parent wrapper, find the hidden delete field.
        let deleteField = fieldParent
          ? fieldParent.querySelector('input[type="hidden"]')
          : null
        // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
        if (deleteField) {
          deleteField.value = 1
          fieldParent.style.display = 'none'
        }
      }
    };
    
    new addFields();
    new removeFields();
    
    //-------------------------------------
    //- Selectize
    //-------------------------------------
    var selectizeCallback = null
    
    // https://stackoverflow.com/questions/57429677/javascript-make-a-word-singular-singularize
    function singularize(word) {
        const endings = {
            ves: 'fe',
            ies: 'y',
            i: 'us',
            zes: 'ze',
            ses: 's',
            es: 'e',
            s: ''
        };
        return word.replace(
            new RegExp(`(${Object.keys(endings).join('|')})$`), 
            r => endings[r]
        );
    };     
    
    function get_new_form(endpoint_url, input, input_label){
      var deferred = $.Deferred();

      //- Add value to input form name
      var name_input_id = "#" + singularize(input_label) + "_name";
      console.log(name_input_id);

      $.ajax({
        url: endpoint_url,
        cache: true,
        type: 'GET',
        data: {},
        headers: {"Turbo-Frame": "modal"},
        dataType: 'html',
        success: function(response, textStatus, xhr) {
          console.log(new Date().toISOString() + " | INFO | AJAX call was successful, loading modal.");
          
          //console.log(response);
          
          // Insert HTML data into Turbo Frame #modal
          //$("#modal").replaceWith(response);
          var endpoint_ts_url = endpoint_url + "?_=" + Date.now()
          
          $("#modal").attr({"reloadable":"", "src": endpoint_ts_url });
          console.log( endpoint_ts_url);
  
        },
        complete: function () {
          
          setTimeout( function() {
            if ($('#Modal').is(':visible')) {
              console.log("Modal Show Event Triggered");
              $("#Modal #biomarker_name").val(input);
            };
            
            
          }, 500);
        }
      }); //- End: AJAX call
      
      return deferred.promise();
    };
    
    function setup_post_form_submit_el(input_label){
      var form_id = "#new_" + singularize(input_label);
      
      console.log(form_id);
      
      $(form_id).on("submit", function(e) {
        e.preventDefault();
        $.ajax({
          method: "POST",
          url: $(this).attr("action"),
          data: $(this).serialize(),
          success: function(response) {
            selectizeCallback({value: response.id, text: response.name});
            selectizeCallback = null;
            
            // Toggle Modal
            $("#Modal").modal('toggle');
            
          }
        });
      });      
    };
    
    $(".selectize").selectize({
      openOnFocus: false,
      onInitialize: function () {
          var that = this;
  
          this.$control.on("click", function () {
              that.ignoreFocusOpen = true;
              setTimeout(function () {
                  that.ignoreFocusOpen = false;
              }, 1);
          });
      },
  
      onFocus: function () {
          if (!this.ignoreFocusOpen) {
              this.open();
          }
      },
      plugins: ["auto_select_on_type"],
      preload: false,
      create: function(input, callback) {
        selectizeCallback = callback;
        //--------------------------------------------------------------------//
        // 1. Fetch the form for the current selectize field.
        // 2. Place input value into corresponding create form field
        // 3. Prevent the default submit
        // 4. The return data from the submit new form
        //--------------------------------------------------------------------//

        var input_label = (this.$input).siblings("label").text().toLowerCase();
        var endpoint_url = "/" + input_label + "/new";
        
        get_new_form(endpoint_url, input, input_label).then( function(input, selectizeCallback){
          
          // setTimeout(
          //   function() 
          //   {
              
          //     if ($('#Modal').is(':visible')) {
          //       console.log("Modal Show Event Triggered");
          //       //$("#Modal #biomarker_name").val(input);
          //       $("#" + singularize(input_label) + "_name").val(input)
          //     };
          
          //   }, 500);
          setup_post_form_submit_el(input, input_label, selectizeCallback);

        });
        
        //selectizeCallback({value: 10, text: input})
        
        // get_new_form(endpoint_url, input_label).then(
     
          // $("#" + singularize(input_label) + "_name").val("Something New");
          
          // $('modal').on('shown.bs.modal', '.modal', function () {
          //   console.log("Modal Show Event Triggered")
          //   $(name_input_id).val("Something New");
          // });
          
          // ?selectizeCallback({value: 10, text: input})
          //setup_post_form_submit_el(input, input_label)
        // );
        
        // var d = new $.Deferred();
        // d.then(
          // function get_new_form(endpoint_url, input_label){
          //   $.ajax({
          //     url: endpoint_url,
          //     cache: true,
          //     type: 'GET',
          //     data: {},
          //     headers: {"Turbo-Frame": "modal"},
          //     dataType: 'html',
          //     success: function(response, textStatus, xhr) {
          //       console.log(new Date().toISOString() + " | INFO | AJAX call was successful, loading modal.");
                
          //       //console.log(response);
                
          //       // Insert HTML data into Turbo Frame #modal
          //       //$("#modal").replaceWith(response);
                
          //       var endpoint_ts_url = endpoint_url + "?_=" + Date.now()
                
          //       $("#modal").attr({"reloadable":"", "src": endpoint_ts_url });
          //       console.log( endpoint_ts_url);
                
          //       // Create values for callback
          //       //callback({ value: "179", text: "Manually Inserted Value" });
                
          //       //- Add value to input form name
          //       var name_input_id = "#" + singularize(input_label) + "_name";
          //       console.log(name_input_id);
                
          //       $('modal').on('shown.bs.modal', '.modal', function () {
          //         console.log("Modal Show Event Triggered")
          //         $(name_input_id).val("Something New");
          //       });
                
          //       var form_id = "#new_" + singularize(input_label);
                
          //       console.log(form_id);
                
          //       $(form_id).on("submit", function(e) {
          //         e.preventDefault();
          //         $.ajax({
          //           method: "POST",
          //           url: $(this).attr("action"),
          //           data: $(this).serialize(),
          //           success: function(response) {
          //             callback({value: response.id, text: response.name});
          //             callback = null;
                      
          //             // Toggle Modal
          //             $(".modal").modal('toggle');
                      
          //           }
          //         });
          //       });           
                
          //     }
          //   }); //- End: AJAX call
          // }
        // ); // add a callback
        // d.resolve(endpoint_url, input_label);
        

      
        //return callback({value: "1235", text: input});
        
        
        
        
        
        // //selectizeCallback = callback;
        
        // // Check is .modal exists
        // //if( !$(".modal").length){}
        
        // var input_label = (this.$input).siblings("label").text().toLowerCase();
      
        // //var endpoint_url = "/" + this.$input[0].dataset.endPoint + "/new";
        // var endpoint_url = "/" + input_label + "/new";
        
        // console.log(new Date().toISOString() + " | INFO | " + "Calling get_new_form(" + endpoint_url + ") function." );
        // get_new_form(endpoint_url, input_label);
        
        // console.log(new Date().toISOString() + " | INFO | " + "Calling setup_post_form_submit_el(" + endpoint_url + ") function." );
        // setup_post_form_submit_el(input, input_label);
        
        // // Turbo.visit(endpoint_url);
        
        // // var modalEl = document.getElementById('Modal')
        // // modalEl.addEventListener('shown.bs.modal', function (event) {
        // //   $('#' + input_label + '_name').val(input);
        // // })
        
        // // $('modal').on('show.bs.modal', '.modal', function () {
        // //   console.log("Modal Show Event Triggered")
        // //   $('#' + input_label + '_name').val(input);  
        // // });        

        // // //- Add value to input form name
        // // var name_input_id = "#" + singularize(input_label) + "_name";
        // // $(name_input_id).val(input);
        
        // console.log(input);
      }
    });
    
  }
}