// https://dev.to/dalezak/bootstrap-modals-using-hotwire-in-rails-3pkh
import * as bootstrap from "bootstrap"
import {
  Controller
} from "@hotwired/stimulus"
export default class extends Controller {
  
  connect() {

    console.log(new Date().toISOString() + " | INFO | Offcanvas controller loaded.");
    
    //- If modal is visible close it.
    if ($('.modal').is(':visible')) {
        $('.modal').modal('hide');
    }
    
    let backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.remove();
    };    
    
    let offcanvasBackdrop = document.querySelector(".offcanvas-backdrop");
    if (offcanvasBackdrop) {
      offcanvasBackdrop.remove();
    }
    
    //this.offcanvas = new bootstrap.Offcanvas(this.element);
    //this.offcanvas.show();
    //this.offcanvas.addEventListener('hidden.bs.offcanvas', (event) => {
    //  this.element.remove();
    //})
    
    var Offcanvas = document.getElementById('offcanvas');
    var offcanvasMenu = document.querySelector("#offcanvas_menu");
    var bsOffcanvas = new bootstrap.Offcanvas(Offcanvas);
    bsOffcanvas.show();

    // document.addEventListener('turbo:submit-end', this.handleSubmit);
    
    // document.addEventListener('turbo:submit-end', this.closeOffcanvas.bind(this));
    
    // function closeOffcanvas(event) {
    //   if (event.detail.success) {
    //     console.log(new Date().toISOString() + " | INFO | Submited Turbo-Stream Request.");
    //     bsOffcanvas.hide()
    //   }
    // }
    
    //------------------------------------------------------------------------//
    // Reset and clean Offcanvas when closed event is fired
    //------------------------------------------------------------------------//
    Offcanvas.addEventListener('hidden.bs.offcanvas', function () {
      console.log("Clearing offcanvas menu inner HTML elements.");
      
      //- Fixed issue with offcanvas flashing on page load, if called and closed
      //- on current page.
      offcanvasMenu.innerHTML = "";
      
    });
    
    //- Fixed issue with offcanvas flashing on page load, if called and closed
    //- on current page.
    //Offcanvas.addEventListener('shown.bs.offcanvas', function () {
    //  console.log("Adding d-flex css class.");
    //  Offcanvas.classList.add('d-flex');
    //});
    
    //----------------------------//
    //- Delete Button Logic
    //----------------------------//
    // var deleteButton = document.getElementById('delete_button')
    
    // deleteButton.addEventListener('click', )
    
    
    
    //----------------------------//
    //- Swipe left or right to close
    //----------------------------//
    let touchstartX = 0
    let touchendX = 0
    
    //const offcanvas = document.querySelector('.offcanvas')
    // Handle left or right swipe
    function handleGesture() {
      if (touchendX > touchstartX+90) {
        console.log('Started:' + touchstartX +"\n" + "End:" + touchendX);
        // close offcanvas here
        bsOffcanvas.hide();
      }
    }
    
    //Offcanvas.addEventListener('touchstart', e => {
    this.element.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX
    })
    
    //Offcanvas.addEventListener('touchend', e => {
    this.element.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      handleGesture()
    })
    
    
    //--------------------------------------------------------------------------
    //- Need to finish converting the below code and get it working. This bit
    //- provides the user a quick filter/search on the html table within the
    //- offcanvas menu. Currently (4/14/22) using the Jquery inside script tags 
    //- on the _offcanvas.html partial. The search input will exist on all of
    //- index partials that get loaded into the offcanvas menu, which is 
    //- everything but trials.
    //--------------------------------------------------------------------------
    
    //- Apply search functionality to table search input.
    //var offcanvasSearchInput = document.querySelector("#offcanvasSearchInput").addEventListener("keyup", function() {
    //    var value = this.value.toLowerCase();
    //    var tableRows = document.querySelector("#dataTable tBody tr")
    //    tableRows.filter(function() {
    //      document.querySelector(this).toggle(document.querySelector(this).text().toLowerCase().indexOf(value) > -1)
    //    });
    //});
    
    //- Above partially works, comes from JQuery below.
    // $(document).ready(function(){
    //   $("#offcanvasSearchInput").on("keyup", function() {
    //     var value = $(this).val().toLowerCase();
    //     $("#dataTable tBody tr").filter(function() {
    //       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    //     });
    //   });
    // });
    
  }
  
}